import React, { useState } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layouts/courseLandingLayout"
import styled from "styled-components"
import PSModal from "../../components/modal"
import Button from "../../components/buttons/linkButton"
import { Container, Col, Row } from "react-bootstrap"
import Navbar from "../../components/navbars/courseLandingNavbarNoMenu"
import CheckoutButton from "../../components/buttons/checkoutButton"
import ReactGA from "react-ga"
import Capacity from "../../components/capacity"
import courseData from "../../utils/course-data"
import moment from "moment"

const HeroBackground = styled.div`
  display: block;
  background: none;
  background-size: cover;
  background-position: center center;
  background-color: var(--blue);
`

const HeroContainer = styled(Container)`
  padding-top: 150px;
  padding-bottom: 150px;
  height: 100%;
  h1 {
    color: var(--white);
    text-align: center;
  }
  h5 {
    color: var(--white);
    padding-top: 15px;
    font-family: "roboto-mono";
  }
  img {
    border: 4px solid var(--black);
    box-shadow: -15px 15px var(--black);
  }
  p {
    color: var(--white);
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
  }

  @media screen and (max-width: 767px) {
    h1,
    p {
      color: var(--white);
    }

    .cta-hero-invite {
      margin-bottom: 20px;
    }
  }
`

const VideoSection = styled.section``

const VideoContainer = styled(Container)`
  margin-top: -200px;
  padding-top: 80px;
  padding-bottom: 60px;
  max-width: 800px;
  h3 {
    text-align: center;
  }
  .cta-container {
    text-align: center;
  }
  img {
    border: 4px solid var(--black);
    box-shadow: -15px 15px var(--black);
  }
  .capacity-container {
    p {
      margin-bottom: 5px;
      font-size: 16px;
      text-align: center;
    }
  }
`

const ModalContainer = styled.div`
  h2 {
    text-align: center;
    padding-bottom: 20px;
  }
  p {
    font-size: 16px;
  }
  .cta-container a {
    width: 100%;
    display: block;

    &:hover {
      background-color: var(--yellow);
    }
  }
`

const EarlyDecision = ({ location }) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = e => setShow(true)

  const renderNextDepositDeadline = course => {
    return courseData.returnNextDepositDate(course.deposit_deadlines)
  }

  const fireGTMEvent = () => {
    ReactGA.event({
      category: "CTA - Button",
      action: "Click",
      label: "waitlist",
    })
  }

  return (
    <Layout>
      <HeroBackground>
        <Navbar location={location} />
        <PSModal
          handleClose={handleClose}
          handleShow={e => handleShow(e)}
          show={show}
        >
          <ModalContainer>
            <h2>Pay Deposit</h2>
            <p>
              You can pay $986 to hold your spot for the upcoming{" "}
              {moment(courseData.nextStartDate("upcomingCourses")).format(
                "MMMM D, YYYY"
              )}{" "}
              programs. There are only 20 spots available in total. Paying this
              before{" "}
              <strong>
                {moment(
                  renderNextDepositDeadline(
                    courseData.nextStartObject("upcomingCourses")
                  ).date
                ).format("MM/DD/YY")}
              </strong>{" "}
              will grant you an extra{" "}
              <strong>
                $
                {
                  renderNextDepositDeadline(
                    courseData.nextStartObject("upcomingCourses")
                  ).discount
                }
              </strong>{" "}
              off tuition. <br></br>
              <br></br> Would you like to hold your spot for the Full-time
              Program, or Part-time Program?
            </p>
            <div className="cta-container">
              <CheckoutButton
                fireGTMEvent={fireGTMEvent}
                text={"Full Time"}
                location={location}
                program={"full"}
              />
              <CheckoutButton
                fireGTMEvent={fireGTMEvent}
                text={"Part Time"}
                location={location}
                program={"part"}
              />
            </div>
          </ModalContainer>
        </PSModal>
        <HeroContainer>
          <Row>
            <Col md={12}>
              <h1>Holding Your Spot, Explained_</h1>
              <p>
                <em>
                  Pay the deposit by{" "}
                  <strong>
                    {moment(
                      renderNextDepositDeadline(
                        courseData.nextStartObject("upcomingCourses")
                      ).date
                    ).format("MM/DD/YY")}
                  </strong>{" "}
                  and recieve a{" "}
                  <strong>
                    $
                    {
                      renderNextDepositDeadline(
                        courseData.nextStartObject("upcomingCourses")
                      ).discount
                    }
                  </strong>{" "}
                  discount on tuition.
                </em>
              </p>
            </Col>
          </Row>
        </HeroContainer>
      </HeroBackground>
      <section>
        <VideoContainer>
          <Row>
            <Col className="video-left" md={12}>
              <iframe
                width="100%"
                height="435"
                src="https://www.youtube.com/embed/8oSoo4m5ZoQ"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="true"
              ></iframe>
              {/* <Capacity /> */}
              <div className="cta-container">
                <Button
                  classToAdd="cta-syllabus"
                  fireGTMEvent={fireGTMEvent}
                  handleCTAClick={e => handleShow(e)}
                  text={"Pay Deposit"}
                />
              </div>
            </Col>
          </Row>
        </VideoContainer>
      </section>
    </Layout>
  )
}

export default EarlyDecision
